import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service/service.service";

@Component({
  selector: "app-view-bill",
  templateUrl: "./view-bill.component.html",
  styleUrls: ["./view-bill.component.css"],
})
export class ViewBillComponent implements OnInit {
  BillId: any;
  billData: any;
  Transporter: any;
  LrNo: any;
  VehicleNo: any;
  Date: any;
  NoOfPackages: any;
  FromPlace: any;
  ToPlace: any;
  InvoiceNo: any;
  Material: any;
  Customers: any;
  BelogisticsRates: any;
  BelogisticsInvoiceNo: any;
  BelogisticsInvoiceDate: any;
  WordTotal: any;
  a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  n: any;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private service: ServiceService
  ) {}

  ngOnInit(): void {
    this.getBillData();
    this.toastr.info(
      "Press Control + P Key to Print Invoice",
      "Print Invoice...!"
    );
  }

  inWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    this.n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!this.n) return;
    var str = "";
    str +=
      this.n[1] != 0
        ? (this.a[Number(this.n[1])] ||
            this.b[this.n[1][0]] + " " + this.a[this.n[1][1]]) + "crore "
        : "";
    str +=
      this.n[2] != 0
        ? (this.a[Number(this.n[2])] ||
            this.b[this.n[2][0]] + " " + this.a[this.n[2][1]]) + "lakh "
        : "";
    str +=
      this.n[3] != 0
        ? (this.a[Number(this.n[3])] ||
            this.b[this.n[3][0]] + " " + this.a[this.n[3][1]]) + "thousand "
        : "";
    str +=
      this.n[4] != 0
        ? (this.a[Number(this.n[4])] ||
            this.b[this.n[4][0]] + " " + this.a[this.n[4][1]]) + "hundred "
        : "";
    str +=
      this.n[5] != 0
        ? (str != "" ? "and " : "") +
          (this.a[Number(this.n[5])] ||
            this.b[this.n[5][0]] + " " + this.a[this.n[5][1]]) +
          "only "
        : "";
    return str;
  }

  getBillData() {
    this.service.getBillData(localStorage.getItem("BillId")).then(
      (res) => {
        this.billData = res;
        this.Transporter = this.billData[0].Transporter;
        this.LrNo = this.billData[0].LrNo;
        this.VehicleNo = this.billData[0].VehicleNo;
        this.Date = this.billData[0].Date;
        this.NoOfPackages = this.billData[0].NoOfPackages;
        this.FromPlace = this.billData[0].FromPlace;
        this.ToPlace = this.billData[0].ToPlace;
        this.InvoiceNo = this.billData[0].InvoiceNo;
        this.Material = this.billData[0].Material;
        this.Customers = this.billData[0].Customers;
        this.BelogisticsRates = this.billData[0].BelogisticsRates;
        this.BelogisticsInvoiceNo = this.billData[0].BelogisticsInvoiceNo;
        this.BelogisticsInvoiceDate = this.billData[0].BelogisticsInvoiceDate;
        this.WordTotal = this.inWords(this.BelogisticsRates);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
