import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http'
import { AppRoutingModule,SiteComponents } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './guard/auth.guard';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
    SiteComponents
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,BrowserAnimationsModule,
    FormsModule,ReactiveFormsModule,
    ToastrModule.forRoot(),
    
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
