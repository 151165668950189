import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 
import {Router} from '@angular/router';
import { ServiceService } from 'src/app/service/service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm:FormGroup;
  constructor(private router :Router,private toastr: ToastrService,private fb: FormBuilder,private service:ServiceService) { }

  ngOnInit(): void {

    if(localStorage.getItem('AdminLogin')){
      this.router.navigate(['/dash']);
    } 

    this.loginForm = this.fb.group({
      Uname: ['', [Validators.required]],
      Pass: ['', [Validators.required]]
    })
  }

  login(){
    this.service.login(this.loginForm.value).then(res =>{
      if(res !=0){
        //console.log(res);
        this.loginForm.reset();
        localStorage.setItem("AdminLogin",JSON.stringify(res));
        this.toastr.success("Login success","Success..!");
        window.location.replace('/dash');
      } else{
        this.toastr.warning("Invalid login credentials","Warning..!");
        this.loginForm.reset();
      }
    },error=>{
      console.log(error);
      //this.toastr.error("Invalid login credentials","Warning..!");
    });
      
  }

}
