import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ServiceService {
  constructor(private http: HttpClient) {}

  /* orginfo start */
  login(data: any) {
    return this.http
      .post(environment.apiURL + "/orginfo/login", data)
      .toPromise();
  }

  getOrgInfo() {
    return this.http.get(environment.apiURL + "/orginfo").toPromise();
  }

  updateProfile(data: any) {
    return this.http
      .put(environment.apiURL + "/orginfo/profile", data)
      .toPromise();
  }

  changePass(data: any) {
    return this.http
      .put(environment.apiURL + "/orginfo/change-password", data)
      .toPromise();
  }

  /* orginfo end */

  /* vehicle start */

  getVehicle() {
    return this.http.get(environment.apiURL + "/vehicle").toPromise();
  }

  singleVehicle(id: any) {
    return this.http.get(environment.apiURL + "/vehicle/" + id).toPromise();
  }

  createVehicle(data: any) {
    return this.http
      .post(environment.apiURL + "/vehicle/create", data)
      .toPromise();
  }

  updateVehicle(data: any) {
    return this.http
      .put(environment.apiURL + "/vehicle/update", data)
      .toPromise();
  }

  removeVehicle(id: any) {
    return this.http
      .delete(environment.apiURL + "/vehicle/remove/" + id)
      .toPromise();
  }

  /* vehicle end */

  /* expenses start */

  getExpenses() {
    return this.http.get(environment.apiURL + "/expenses").toPromise();
  }

  singleExpenses(id: any) {
    return this.http.get(environment.apiURL + "/expenses/" + id).toPromise();
  }

  createExpenses(data: any) {
    return this.http
      .post(environment.apiURL + "/expenses/create", data)
      .toPromise();
  }

  updateExpenses(data: any) {
    return this.http
      .put(environment.apiURL + "/expenses/update", data)
      .toPromise();
  }

  removeExpenses(id: any) {
    return this.http
      .delete(environment.apiURL + "/expenses/remove/" + id)
      .toPromise();
  }

  /* expenses end */

  /* customer start */

  getCustomers() {
    return this.http.get(environment.apiURL + "/customer").toPromise();
  }

  singleCustomer(id: any) {
    return this.http.get(environment.apiURL + "/customer/" + id).toPromise();
  }

  createCustomer(data: any) {
    return this.http
      .post(environment.apiURL + "/customer/create", data)
      .toPromise();
  }

  updateCustomer(data: any) {
    return this.http
      .put(environment.apiURL + "/customer/update", data)
      .toPromise();
  }

  removeCustomer(id: any) {
    return this.http
      .delete(environment.apiURL + "/customer/remove/" + id)
      .toPromise();
  }

  /* customer end */

  /* product start */

  getProducts() {
    return this.http.get(environment.apiURL + "/product").toPromise();
  }

  singleProduct(id: any) {
    return this.http.get(environment.apiURL + "/product/" + id).toPromise();
  }

  createProduct(data: any) {
    return this.http
      .post(environment.apiURL + "/product/create", data)
      .toPromise();
  }

  updateProduct(data: any) {
    return this.http
      .put(environment.apiURL + "/product/update", data)
      .toPromise();
  }

  removeProduct(id: any) {
    return this.http
      .delete(environment.apiURL + "/product/remove/" + id)
      .toPromise();
  }

  removeBill(id: any) {
    return this.http
      .delete(environment.apiURL + "/invoice/delete-bill/" + id)
      .toPromise();
  }

  removeAllBill() {
    return this.http
      .delete(environment.apiURL + "/invoice/delete-all-bill")
      .toPromise();
  }

  /* product end */

  /* location start */

  getLocation() {
    return this.http.get(environment.apiURL + "/location").toPromise();
  }

  singleLocation(id: any) {
    return this.http.get(environment.apiURL + "/location/" + id).toPromise();
  }

  createLocation(data: any) {
    return this.http
      .post(environment.apiURL + "/location/create", data)
      .toPromise();
  }

  updateLocation(data: any) {
    return this.http
      .put(environment.apiURL + "/location/update", data)
      .toPromise();
  }

  removeLocation(id: any) {
    return this.http
      .delete(environment.apiURL + "/location/remove/" + id)
      .toPromise();
  }

  /* location end */

  /* Invoice start  */

  getMaxInvoiceID() {
    return this.http
      .get(environment.apiURL + "/invoice/getMaxInvoiceID")
      .toPromise();
  }

  getVehicleDetail(id: string) {
    return this.http
      .get(environment.apiURL + "/invoice/getVehicle/" + id)
      .toPromise();
  }

  getCityLocation(id: string) {
    return this.http
      .get(environment.apiURL + "/invoice/shipLocation/" + id)
      .toPromise();
  }

  createInvoiceItem(data: any) {
    return this.http
      .post(environment.apiURL + "/invoice/addInvoiceItem", data)
      .toPromise();
  }

  getInvoiceItem(id: number) {
    return this.http
      .get(environment.apiURL + "/invoice/getInvoiceItem/" + id)
      .toPromise();
  }

  updateInvoiceItem(data: any) {
    return this.http
      .put(environment.apiURL + "/invoice/updateInvoiceItem", data)
      .toPromise();
  }

  removeInvoiceItem(id: any) {
    return this.http
      .delete(environment.apiURL + "/invoice/removeInvoiceItem/" + id)
      .toPromise();
  }

  saveInvoice(data: any) {
    return this.http
      .post(environment.apiURL + "/invoice/saveInvoice/", data)
      .toPromise();
  }

  getAllInvoiceItem() {
    return this.http
      .get(environment.apiURL + "/invoice/getAllInvoiceItem")
      .toPromise();
  }

  getInvoiceData(id: any) {
    return this.http
      .get(environment.apiURL + "/invoice/getInvoiceData/" + id)
      .toPromise();
  }
  /* Invoice end  */

  getExcelData(id: any) {
    return this.http
      .get(environment.apiURL + "/invoice/get/excel/data/" + id)
      .toPromise();
  }

  getAllBillingLocation() {
    return this.http
      .get(environment.apiURL + "/invoice/get-billing-locations")
      .toPromise();
  }

  getExcelDataByInvoiceId(data: any) {
    return this.http
      .post(environment.apiURL + "/invoice/get-excel-data/by-invoice-no", data)
      .toPromise();
  }

  getBillData(BillId: any) {
    return this.http
      .get(environment.apiURL + "/invoice/view-bill/" + BillId)
      .toPromise();
  }

  uploadExcel(data: any) {
    return this.http
      .post(environment.apiURL + "/invoice/upload-excel", data)
      .toPromise();
  }

  // Get image base64 data
}
