<div class="cm-login">

    <div class="text-center" style="padding:90px 0 30px 0;background:#fff;border-bottom:1px solid #ddd">
      <img class="img-fluid" src="../../../assets/img/loginlogo.jpg">
    </div>
  
    <div class="col-sm-6 col-md-4 col-lg-3" style="margin:40px auto; float:none;">
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="col-xs-12">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon"><i class="fa fa-fw fa-user"></i></div>
              <input formControlName="Uname" type="text" name="username" class="form-control" placeholder="Username">
            </div>
            <span *ngIf="!loginForm.get('Uname').valid && loginForm.get('Uname').touched">Please enter username.</span>
          </div>
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon"><i class="fa fa-fw fa-lock"></i></div>
              <input formControlName="Pass" type="password" name="password" class="form-control" placeholder="Password">
            </div>
            <span *ngIf="!loginForm.get('Pass').valid && loginForm.get('Pass').touched">Please enter password.</span>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="checkbox"><label><input type="checkbox"> Remember me</label></div>
        </div>
        <div class="col-xs-6">
          <button [disabled]="!loginForm.valid" type="submit" class="btn btn-block btn-primary">Sign in</button>
        </div>
      </form>
    </div>
  </div>