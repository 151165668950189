import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service/service.service";

@Component({
  selector: "app-view-invoice",
  templateUrl: "./view-invoice.component.html",
  styleUrls: ["./view-invoice.component.css"],
})
export class ViewInvoiceComponent implements OnInit {
  iData;
  data;
  MaxIid;
  GetIid;
  CName;
  Address;
  GstNo;
  Iid;
  IDate;
  WordTotal;
  Total;
  a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  n: any;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private service: ServiceService
  ) {}

  ngOnInit(): void {
    this.getInvoiceData();
    this.toastr.info(
      "Press Control + P Key to Print Invoice",
      "Print Invoice...!"
    );
  }

  inWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    this.n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!this.n) return;
    var str = "";
    str +=
      this.n[1] != 0
        ? (this.a[Number(this.n[1])] ||
            this.b[this.n[1][0]] + " " + this.a[this.n[1][1]]) + "crore "
        : "";
    str +=
      this.n[2] != 0
        ? (this.a[Number(this.n[2])] ||
            this.b[this.n[2][0]] + " " + this.a[this.n[2][1]]) + "lakh "
        : "";
    str +=
      this.n[3] != 0
        ? (this.a[Number(this.n[3])] ||
            this.b[this.n[3][0]] + " " + this.a[this.n[3][1]]) + "thousand "
        : "";
    str +=
      this.n[4] != 0
        ? (this.a[Number(this.n[4])] ||
            this.b[this.n[4][0]] + " " + this.a[this.n[4][1]]) + "hundred "
        : "";
    str +=
      this.n[5] != 0
        ? (str != "" ? "and " : "") +
          (this.a[Number(this.n[5])] ||
            this.b[this.n[5][0]] + " " + this.a[this.n[5][1]]) +
          "only "
        : "";
    return str;
  }

  getInvoiceData() {
    if (localStorage.getItem("InvoiceID")) {
      this.MaxIid = localStorage.getItem("InvoiceID");
      this.getInvoiceDataByIid(this.MaxIid);
    } else {
      this.service.getMaxInvoiceID().then(
        (res) => {
          this.GetIid = res;
          if (this.GetIid.Iid == null) {
            this.toastr.warning(
              "Invoice not created yet..!",
              "Record not found..!"
            );
          } else {
            this.getInvoiceDataByIid(this.GetIid.Iid);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  getInvoiceDataByIid(id: any) {
    this.service.getInvoiceData(id).then(
      (res) => {
        this.iData = res;
        console.log(this.iData);
        this.CName = this.iData[0].CName;
        this.Address = this.iData[0].Address;
        this.GstNo = this.iData[0].GstNo;
        this.Iid = this.iData[0].Iid;
        this.IDate = this.iData[0].Date;
        this.WordTotal = this.inWords(this.iData[0].Total);
        this.Total = this.iData[0].Total;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
