<div *ngIf="this.iData">
    <div class="container">
        <div class="panel panel-default">
            <div class="panel-heading">Tax Invoice</div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-xs-6">
                    <h4>Bill To Party</h4>
                    <h5>M/S : {{CName}}</h5>
                    <p>{{Address}}</p>
                    <p>GSTN No : {{GstNo}}</p>
                    </div>

                   
                    <div class="col-xs-6 text-right">
                        <h4>Invoice No : {{Iid}}</h4>
                        <h5>Invoice Date : {{IDate | date: 'dd/MM/yyyy'}}</h5>
                        <p>Billing Location : Navi Mumbai </p>
                        <p>PAN NO:- BBFPJ5644G </p>
                        <p>GSTIN:- 27BBFPJ5644G1ZM </p>   
                    </div>
                </div><br>

                <div class="row">
                    <div class="col-md-12">
                        <div class="panel panel-default">
                                <table class="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>LR No <br>Date</th>
                                            <th>No.Of Packages</th>
                                            <th>Particular<br>Vehicle No.</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Ship To Location</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of iData; let i = index">
                                            <th scope="row">{{i+1}}</th>
                                            <td>{{item.LRNo}} <br>{{item.Date | date: 'dd/MM/yyyy'}} </td>
                                            <td>{{item.Packages}}</td>
                                            <td>{{item.PName}} <br>{{item.VNo}} </td>
                                            <td>{{item.FromLocation}}</td>
                                            <td>{{item.ToLocation}}</td>
                                            <td>{{item.ShipLocation}} <br> Extra Charges </td>
                                            <td>{{item.BhagwatiRates}} <br>{{item.LoadingAndCharges}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                             
                        </div>
                    </div>
                        
                </div>
                <hr>

                <div class="row">
                    <div class="col-xs-8">
                        <h5>Rs.(In words) : {{WordTotal}}</h5>
                    </div>
                    
                    <div class="col-xs-4 text-right">
                        <h4>Total : {{Total}}</h4>
                    </div>
                   
                </div>
                <hr>
                <div class="row">
                    <div class="col-xs-12">
                        <h5>Note/Remark's :- Enclosed LR copy.</h5>
                    </div>
                </div>
                <hr>

                <div class="row">
                     <div class="col-xs-6">
                        <h4>Bank Details</h4>
                        <h5>A/C Name : Bhagwati Enterprises</h5>
                        <h5>Bank Name : SVC CO-OPERATIVE BANK LTD</h5>
                        <h5>Branch : SANPADA (EAST)</h5>
                        <h5>Account No : 103404180000520</h5>
                        <h5>IFSC Code : SVCB0000034</h5>
                    </div>
                    <div class="col-xs-6">
                        <h4 class="text-right" style="padding-bottom: 100px;">For Bhagwati Enterprises</h4>
                        <h4 class="text-right">Authorised Signatory</h4>
                    </div>
                </div>
                <hr>
                <!-- <button (click)="printPage()" type="button" class="btn btn-warning" style="margin-right: 1rem;">Download Invoice</button> -->

            </div>
        </div>
    </div>
 </div>

 <!-- <div>
    <div class="container-fluid">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="form-group text-right" style="margin-top:20px">
                    <button (click)="printPage()" class="btn btn-primary" style="margin-right: 1rem;">Print Invoice</button>
                    <button type="button" class="btn btn-warning" style="margin-right: 1rem;">Download Invoice</button>
                </div>
            </div>
        </div>

    </div>
 </div> -->
