<div>
  <div class="container">
    <div class="panel panel-default">
      <div class="row">
        <div class="col-md-12">
          <img
            src="assets/img/header.jpg"
            class="img-fluid"
            width="100%"
            alt=""
          />
        </div>
      </div>
      <div class="panel-heading">Tax Invoice</div>
      <div class="panel-body">
        <div class="row">
          <div class="col-xs-6">
            <h4>Bill To Party</h4>
            <h5>
              M/S. Wanhua International (India) Pvt.Ltd <br />
              S-54 vatika business Center 7th Floor,<br />
              Suprem Park, Hiranandani Garden,<br />
              Powai Mumbai 400076 Maharashtra India.<br />
              <br />
              GSTN no.27AABCW2030Q1Z4
            </h5>
          </div>

          <div class="col-xs-6 text-right">
            <h4>BE Bill No : {{ BelogisticsInvoiceNo }}</h4>
            <h5>Date : {{ BelogisticsInvoiceDate }}</h5>
            <p>Billing Location : Navi Mumbai</p>
            <p>PAN NO:- BBFPJ5644G</p>
            <p>GSTIN:- 27BBFPJ5644G1ZM</p>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-md-12">
            <div class="panel panel-default">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>LR No <br />Date</th>
                    <th>No.Of Packages / Qty in MT /<br>Invoice No</th>
                    <th>Particular<br />Vehicle No.</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Customer Name</th>
                    <th>Freight Amount ( In Rupees)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>{{ LrNo }} <br />{{ Date }}</td>
                    <td>{{ NoOfPackages }}<br />{{ InvoiceNo }}, Date : {{Date}}</td>
                    <td>{{ Material }} <br />{{ VehicleNo }}</td>
                    <td>{{ FromPlace }}</td>
                    <td>{{ ToPlace }}</td>
                    <td>{{ Customers }}</td>
                    <td class="text-right">{{ BelogisticsRates }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <hr />

        <div class="row">
          <div class="col-xs-8">
            <p>Rs.(In words) : {{ WordTotal | titlecase }}</p>
          </div>

          <div class="col-xs-4 text-right">
            <h4>Total : {{ BelogisticsRates }}</h4>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-xs-12">
            <p>Note/Remark's :- Enclosed LR copy.</p>
          </div>
        </div>
        <hr />

        <div class="row">
          <div class="col-xs-9">
            <h4>Bank Details</h4>
            <h5>A/C Name : Belogistics</h5>
            <h5>Bank Name : HDFC BANK LTD</h5>
            <h5>Branch : Savargaon</h5>
            <h5>Account No : 50200055583238</h5>
            <h5>IFSC Code : HDFC0009557</h5>
          </div>
          <div class="col-xs-3">
            <h4 style="padding-bottom: 100px">
              For BELOGISTICS
            </h4>
            <h4>Authorised Signatory</h4>
          </div>
        </div>
        <!-- <button (click)="printPage()" type="button" class="btn btn-warning" style="margin-right: 1rem;">Download Invoice</button> -->
      </div>
    </div>
  </div>
</div>

<!-- <div>
    <div class="container-fluid">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="form-group text-right" style="margin-top:20px">
                    <button (click)="printPage()" class="btn btn-primary" style="margin-right: 1rem;">Print Invoice</button>
                    <button type="button" class="btn btn-warning" style="margin-right: 1rem;">Download Invoice</button>
                </div>
            </div>
        </div>

    </div>
 </div> -->
