import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';
import { LoginComponent } from './site/login/login.component';
import { ForgotPassComponent } from './site/forgot-pass/forgot-pass.component';
import { ViewInvoiceComponent } from './site/view-invoice/view-invoice.component';
import { ViewBillComponent } from './site/view-bill/view-bill.component';


const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotPassComponent },
  { path: 'invoice', component: ViewInvoiceComponent,canActivate:[AuthGuard] },
  { path: 'bill-invoice', component: ViewBillComponent,canActivate:[AuthGuard] },
  { path: 'dash', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),canActivate:[AuthGuard] },
  { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const SiteComponents = [
  LoginComponent,ForgotPassComponent,ViewInvoiceComponent,ViewBillComponent
];
